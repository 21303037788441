@media (prefers-color-scheme: light) {
  .relatedFileLink {
    color: #000;
  }
}
@media (prefers-color-scheme: dark) {
  .relatedFileLink {
    color: #fff;
  }
}
